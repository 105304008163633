@import url(_variables);
@import "_sizesSelect2";
@import "_sizesSelect2Mobile";

.v-select.filter-select {
  .vs__dropdown-toggle{
    border-radius: 28px; border: none; background: @gray10; font-size: 28px; font-family: @EuclidSemibold, sans-serif;  padding: 7px 28px 9px;  width: 100%;
  }
  .vs__selected-options{
    padding: 0;
  }
  .vs__selected {
    padding: 0; margin: 0; border: 0; color: @gray3;
  }
  .vs__actions{
    padding: 0; margin: 0;
  }
  .vs__open-indicator{
    background: url(../images/arrow-down.svg) no-repeat center / contain; width: 13px; height: 100%;
    &:before{
      display: none;
    }
  }
  .vs__dropdown-menu {
    min-width: 100%;
    padding: 0;
    border: 0;
    box-shadow: none;
    border-radius: 0 0 28px 28px;
    background: @gray10;
    overflow-y: auto;

    .vs__active {
      color: @gray3;
      background: @green2;
    }

    > .vs__dropdown-option--selected {
      background: transparent;
    }

    > .vs__dropdown-option--highlight {
      background: @green;
      color: @gray3;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: @green;
      border-radius: 5px;
    }
  }

  li {
      font-size: 28px; font-family: @EuclidSemibold, sans-serif;
      padding: 7px 28px 9px;
      color: @gray3;

  }

  /*open*/
  &.vs--single{
    &.vs--open{
      .vs__selected{
        position: relative;
      }
      .vs__open-indicator{
        transform: rotate(180deg);
      }
      .vs__dropdown-toggle{
        border-radius: 28px 28px 0 0;
      }
    }
  }

  &.small{
    .vs__dropdown-toggle{
      font-size: 20px; font-family: @EuclidMedium, sans-serif; padding-top: 13px; padding-bottom: 15px;
    }
    li {
        font-size: 20px; font-family: @EuclidMedium, sans-serif; padding-top: 13px; padding-bottom: 15px;
    }
  }
}

.v-select.contacts-select {
  position: fixed; top:44px; left:0; width: 100%; z-index: 11;
  .vs__dropdown-toggle{
    border-radius: 0; border: none; background: @green; font-size: 15.5px; font-family: @EuclidMedium, sans-serif; padding: 12px 16px;
  }
  .vs__selected-options{
    padding: 0;
  }
  .vs__selected{
    padding: 0; margin: 0; border: 0; color: @gray3;
  }
  .vs__actions{
    padding: 0; margin: 0;
  }
  .vs__open-indicator{
    background: url(../images/arrow-down.svg) no-repeat center / contain; width: 13px; height: 100%;
    &:before{
      display: none;
    }
  }
  .vs__dropdown-menu {
    min-width: 100%;
    padding: 0;
    border: 0;
    box-shadow: none;
    border-radius: 0;
    background: @gray10;
    overflow-y: hidden;

    > .vs__active {
      color: @gray3;
      background: @green2;
    }

    > .vs__dropdown-option--selected {
      background: transparent;
    }

    > .vs__dropdown-option--highlight {
      background: @green;
      color: @gray3;
    }
  }

  li {
      font-size: 15.5px; font-family: @EuclidMedium, sans-serif;
      padding: 12px 16px;
      color: @gray3;
  }

  /*open*/
  &.vs--single {
    &.vs--open {
      .vs__selected {
        position: relative;
      }

      .vs__open-indicator {
        transform: rotate(180deg);
      }

      .vs__dropdown-toggle {
        border-radius: 0;
      }
    }
  }
}

.sizesSelect2(1);

@ratio1: ~"(min-aspect-ratio: 3/1)";

@media screen and (@ratio1) and (orientation: landscape) and (min-width: 768px) {
  .sizesSelect2(.8);
}

@media screen and (max-width: 767px) {
  .sizesSelect2Mobile(1);
}

@import url(../assets/fonts/stylesheet.css);
.v-select.filter-select {
  /*open*/
}
.v-select.filter-select .vs__dropdown-toggle {
  border-radius: 28px;
  border: none;
  background: #f9f9f9;
  font-size: 28px;
  font-family: 'EuclidCircularA-Semibold', sans-serif, sans-serif;
  padding: 7px 28px 9px;
  width: 100%;
}
.v-select.filter-select .vs__selected-options {
  padding: 0;
}
.v-select.filter-select .vs__selected {
  padding: 0;
  margin: 0;
  border: 0;
  color: #393939;
}
.v-select.filter-select .vs__actions {
  padding: 0;
  margin: 0;
}
.v-select.filter-select .vs__open-indicator {
  background: url(../assets/images/arrow-down.svg) no-repeat center / contain;
  width: 13px;
  height: 100%;
}
.v-select.filter-select .vs__open-indicator:before {
  display: none;
}
.v-select.filter-select .vs__dropdown-menu {
  min-width: 100%;
  padding: 0;
  border: 0;
  box-shadow: none;
  border-radius: 0 0 28px 28px;
  background: #f9f9f9;
  overflow-y: auto;
}
.v-select.filter-select .vs__dropdown-menu .vs__active {
  color: #393939;
  background: #4ef2ce;
}
.v-select.filter-select .vs__dropdown-menu > .vs__dropdown-option--selected {
  background: transparent;
}
.v-select.filter-select .vs__dropdown-menu > .vs__dropdown-option--highlight {
  background: #28e1b9;
  color: #393939;
}
.v-select.filter-select .vs__dropdown-menu::-webkit-scrollbar {
  width: 10px;
}
.v-select.filter-select .vs__dropdown-menu::-webkit-scrollbar-track {
  background: none;
}
.v-select.filter-select .vs__dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #28e1b9;
  border-radius: 5px;
}
.v-select.filter-select li {
  font-size: 28px;
  font-family: 'EuclidCircularA-Semibold', sans-serif, sans-serif;
  padding: 7px 28px 9px;
  color: #393939;
}
.v-select.filter-select.vs--single.vs--open .vs__selected {
  position: relative;
}
.v-select.filter-select.vs--single.vs--open .vs__open-indicator {
  transform: rotate(180deg);
}
.v-select.filter-select.vs--single.vs--open .vs__dropdown-toggle {
  border-radius: 28px 28px 0 0;
}
.v-select.filter-select.small .vs__dropdown-toggle {
  font-size: 20px;
  font-family: 'EuclidCircularA-Medium', sans-serif, sans-serif;
  padding-top: 13px;
  padding-bottom: 15px;
}
.v-select.filter-select.small li {
  font-size: 20px;
  font-family: 'EuclidCircularA-Medium', sans-serif, sans-serif;
  padding-top: 13px;
  padding-bottom: 15px;
}
.v-select.contacts-select {
  position: fixed;
  top: 44px;
  left: 0;
  width: 100%;
  z-index: 11;
  /*open*/
}
.v-select.contacts-select .vs__dropdown-toggle {
  border-radius: 0;
  border: none;
  background: #28e1b9;
  font-size: 15.5px;
  font-family: 'EuclidCircularA-Medium', sans-serif, sans-serif;
  padding: 12px 16px;
}
.v-select.contacts-select .vs__selected-options {
  padding: 0;
}
.v-select.contacts-select .vs__selected {
  padding: 0;
  margin: 0;
  border: 0;
  color: #393939;
}
.v-select.contacts-select .vs__actions {
  padding: 0;
  margin: 0;
}
.v-select.contacts-select .vs__open-indicator {
  background: url(../assets/images/arrow-down.svg) no-repeat center / contain;
  width: 13px;
  height: 100%;
}
.v-select.contacts-select .vs__open-indicator:before {
  display: none;
}
.v-select.contacts-select .vs__dropdown-menu {
  min-width: 100%;
  padding: 0;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  background: #f9f9f9;
  overflow-y: hidden;
}
.v-select.contacts-select .vs__dropdown-menu > .vs__active {
  color: #393939;
  background: #4ef2ce;
}
.v-select.contacts-select .vs__dropdown-menu > .vs__dropdown-option--selected {
  background: transparent;
}
.v-select.contacts-select .vs__dropdown-menu > .vs__dropdown-option--highlight {
  background: #28e1b9;
  color: #393939;
}
.v-select.contacts-select li {
  font-size: 15.5px;
  font-family: 'EuclidCircularA-Medium', sans-serif, sans-serif;
  padding: 12px 16px;
  color: #393939;
}
.v-select.contacts-select.vs--single.vs--open .vs__selected {
  position: relative;
}
.v-select.contacts-select.vs--single.vs--open .vs__open-indicator {
  transform: rotate(180deg);
}
.v-select.contacts-select.vs--single.vs--open .vs__dropdown-toggle {
  border-radius: 0;
}
.v-select.filter-select {
  /*open*/
}
.v-select.filter-select .vs__dropdown-toggle {
  border-radius: 1.45833333vw;
  font-size: 1.45833333vw;
  padding: 0.36458333vw 1.45833333vw 0.46875vw;
}
.v-select.filter-select .vs__open-indicator {
  width: 0.67708333vw;
}
.v-select.filter-select .vs__dropdown-menu {
  border-radius: 0 0 1.45833333vw 1.45833333vw;
}
.v-select.filter-select li {
  font-size: 1.45833333vw;
  padding: 0.36458333vw 1.45833333vw 0.46875vw;
}
.v-select.filter-select.single.open .vs__dropdown-toggle {
  border-radius: 1.45833333vw 1.45833333vw 0 0;
}
.v-select.filter-select.small .vs__dropdown-toggle {
  font-size: 1.04166667vw;
  padding-top: 0.67708333vw;
  padding-bottom: 0.78125vw;
}
.v-select.filter-select.small li {
  font-size: 1.04166667vw;
  padding-top: 0.67708333vw;
  padding-bottom: 0.78125vw;
}
@media screen and ((min-aspect-ratio: 3/1)) and (orientation: landscape) and (min-width: 768px) {
  .v-select.filter-select {
    /*open*/
  }
  .v-select.filter-select .vs__dropdown-toggle {
    border-radius: 1.16666667vw;
    font-size: 1.16666667vw;
    padding: 0.29166667vw 1.16666667vw 0.375vw;
  }
  .v-select.filter-select .vs__open-indicator {
    width: 0.54166667vw;
  }
  .v-select.filter-select .vs__dropdown-menu {
    border-radius: 0 0 1.16666667vw 1.16666667vw;
  }
  .v-select.filter-select li {
    font-size: 1.16666667vw;
    padding: 0.29166667vw 1.16666667vw 0.375vw;
  }
  .v-select.filter-select.single.open .vs__dropdown-toggle {
    border-radius: 1.16666667vw 1.16666667vw 0 0;
  }
  .v-select.filter-select.small .vs__dropdown-toggle {
    font-size: 0.83333333vw;
    padding-top: 0.54166667vw;
    padding-bottom: 0.625vw;
  }
  .v-select.filter-select.small li {
    font-size: 0.83333333vw;
    padding-top: 0.54166667vw;
    padding-bottom: 0.625vw;
  }
}
@media screen and (max-width: 767px) {
  .v-select.contacts-select {
    top: 13.75vw;
  }
  .v-select.contacts-select .vs__dropdown-toggle {
    font-size: 4.84375vw;
    padding: 3.75vw 5vw;
    height: 16.25vw;
  }
  .v-select.contacts-select .vs__open-indicator {
    width: 4.0625vw;
  }
  .v-select.contacts-select li {
    font-size: 4.84375vw;
    padding: 3.75vw 5vw;
  }
}

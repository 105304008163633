.sizesSelect2(@factor) {
  .v-select.filter-select {
    .vs__dropdown-toggle {
      border-radius: 28 / 1920 * 100vw * @factor;
      font-size: 28 / 1920 * 100vw * @factor;
      padding: 7 / 1920 * 100vw * @factor 28 / 1920 * 100vw * @factor 9 / 1920 * 100vw * @factor;
    }

    .vs__open-indicator {
      width: 13 / 1920 * 100vw * @factor;
    }

    .vs__dropdown-menu {
      border-radius: 0 0 28 / 1920 * 100vw * @factor 28 / 1920 * 100vw * @factor;
    }

    li {
        font-size: 28 / 1920 * 100vw * @factor;
        padding: 7 / 1920 * 100vw * @factor 28 / 1920 * 100vw * @factor 9 / 1920 * 100vw * @factor;
    }

    /*open*/

    &.single {
      &.open {
        .vs__dropdown-toggle {
          border-radius: 28 / 1920 * 100vw * @factor 28 / 1920 * 100vw * @factor 0 0;
        }
      }
    }

    &.small {
      .vs__dropdown-toggle {
        font-size: 20 / 1920 * 100vw * @factor;
        padding-top: 13 / 1920 * 100vw * @factor;
        padding-bottom: 15 / 1920 * 100vw * @factor;
      }

      li {
          font-size: 20 / 1920 * 100vw * @factor;
          padding-top: 13 / 1920 * 100vw * @factor;
          padding-bottom: 15 / 1920 * 100vw * @factor;
      }
    }
  }
}

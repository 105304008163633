.sizesSelect2Mobile(@factor) {
  .v-select.contacts-select {
    top: 44 / 320 * 100vw * @factor;

    .vs__dropdown-toggle {
      font-size: 15.5 / 320 * 100vw * @factor;
      padding: 12 / 320 * 100vw * @factor 16 / 320 * 100vw * @factor;
      height: 52 / 320 * 100vw * @factor;
    }

    .vs__open-indicator {
      width: 13 / 320 * 100vw * @factor;
    }

    li {
        font-size: 15.5 / 320 * 100vw * @factor;
        padding: 12 / 320 * 100vw * @factor 16 / 320 * 100vw * @factor;
    }
  }
}
